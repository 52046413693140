<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 w-100">
        <div
          class="
            d-flex
            align-items-center
            justify-content-start
            cursor-pointer
            back-menu
          "
          @click="backMenu"
        >
          <i class="bi bi-arrow-left h3 me-4 text-white back-menu"></i>
          <h3 class="fw-bolder m-0 back-menu text-white" v-if="wilayah">
            Grafik Suara Partai Wilayah : {{ wilayah.name }}
          </h3>
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-9">
        <!-- <grafik-suara widget-classes="card-xl-stretch mb-xl-8" /> -->
        <div
          style="width: 100%; height: 500px"
          class="chartdiv"
          ref="chartdiv"
        ></div>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import CryptoJS from "crypto-js";

am4core.useTheme(am4themes_animated);

// import GrafikSuara from "@/components/custom/GrafikSuara.vue";

export default defineComponent({
  name: "Heatmaps-Chart",
  props: ["id"],
  components: {
    // GrafikSuara,
  },
  setup(props) {
    setCurrentPageTitle("Heatmaps");
    const chartdiv = ref(null);

    const path = window.location.origin + "/other/png/partai/";

    const store = useStore();
    const router = useRouter();

    store.commit("SET_ACTIVE_MENU", "heatmaps");

    const dataId = CryptoJS.AES.decrypt(props.id, "PKS.id").toString(
      CryptoJS.enc.Utf8
    );
    if (!dataId) {
      router.push({ name: "heat-maps" });
    }

    store.dispatch("getPartyHeatmap", { territory: dataId });
    store.dispatch("detailTerritory", dataId );

    const data = computed(() => store.state.HeatmapModule.party);
    const wilayah = computed(() => store.state.TerritoryModule.detail.territory);

    watch(data, () => {
      if (data.value) {
        updateChart();
      }
    });

    const updateChart = () => {
      const chart = am4core.create(chartdiv.value, am4charts.XYChart);
      chart.hiddenState.properties.opacity = 0;

      chart.paddingBottom = 30;
      chart.paddingRight = 10;

      const dummy = ref([]);

      data.value.map((item) => {
        dummy.value.push({
          id: item.party.id,
          name: item.party.name,
          suara: item.party.count,
          href: path + item.party.logo,
        });
      });

      chart.data = dummy.value;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "name";
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.labels.template.dy = 35;
      categoryAxis.renderer.tooltip.dy = 35;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.fillOpacity = 0.3;
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.min = 0;
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.baseGrid.strokeOpacity = 0;
      valueAxis.paddingRight = 30;

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "suara";
      series.dataFields.categoryX = "name";
      series.tooltipText = "{valueY.value}";
      series.tooltip.pointerOrientation = "vertical";
      series.tooltip.dy = -6;
      series.columnsContainer.zIndex = 100;

      let columnTemplate = series.columns.template;
      columnTemplate.width = am4core.percent(50);
      columnTemplate.maxWidth = 66;
      columnTemplate.column.cornerRadius(60, 60, 10, 10);
      columnTemplate.strokeOpacity = 0;

      columnTemplate.events.on(
        "hit",
        function (ev) {
          const id = CryptoJS.AES.encrypt(
            ev.target.dataItem.dataContext.id.toString(),
            "PKS.id"
          ).toString();

          router.push({
            name: "heat-maps.chart.detail",
            params: { id: id, wilayah: props.id },
          });
        },
        this
      );

      series.tooltipText = "{valueY.value}";
      chart.cursor = new am4charts.XYCursor();

      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        dataField: "valueY",
        max: am4core.color("#FE5001"),
        min: am4core.color("#FEB67A"),
      });
      series.mainContainer.mask = undefined;

      let cursor = new am4charts.XYCursor();
      chart.cursor = cursor;
      cursor.lineX.disabled = true;
      cursor.lineY.disabled = true;
      cursor.behavior = "none";

      let bullet = columnTemplate.createChild(am4charts.CircleBullet);
      bullet.circle.radius = 30;
      bullet.valign = "bottom";
      bullet.align = "center";
      bullet.isMeasured = true;
      bullet.mouseEnabled = false;
      bullet.verticalCenter = "bottom";
      bullet.interactionsEnabled = false;

      let hoverState = bullet.states.create("hover");
      let outlineCircle = bullet.createChild(am4core.Circle);
      outlineCircle.adapter.add("radius", function (radius, target) {
        let circleBullet = target.parent;
        return circleBullet.circle.pixelRadius - 5;
      });

      let image = bullet.createChild(am4core.Image);
      image.width = 60;
      image.height = 60;
      image.horizontalCenter = "middle";
      image.verticalCenter = "middle";
      image.propertyFields.href = "href";

      image.adapter.add("mask", function (mask, target) {
        let circleBullet = target.parent;
        return circleBullet.circle;
      });

      let previousBullet;
      chart.cursor.events.on("cursorpositionchanged", function (event) {
        let dataItem = series.tooltipDataItem;

        if (dataItem.column) {
          let bullet = dataItem.column.children.getIndex(1);

          if (previousBullet && previousBullet != bullet) {
            previousBullet.isHover = false;
          }

          if (previousBullet != bullet) {
            let hs = bullet.states.getKey("hover");
            hs.properties.dy = -bullet.parent.pixelHeight + 30;
            bullet.isHover = true;

            previousBullet = bullet;
          }
        }
      });
    };

    const backMenu = () => {
      router.push({ name: "heat-maps" });
    };

    onUnmounted(() => {
      store.commit("SET_PARTY_HEATMAP", []);
    });

    return {
      chartdiv,
      wilayah,
      backMenu,
    };
  },
});
</script>

<style>
.chartdiv {
  width: 100%;
  height: 500px;
}
</style>
